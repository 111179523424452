@use "sass:math";

@import 'styles/all_vars';

$miniFilterPadding: 0.8rem;
$miniFilterHeight: 3.5625rem;

#eb-filter-root .hide {
  display: none;
}

.mini-filter {
  position: relative;
  //margin-top: $miniFilterHeight;
  background-color: $blue;
  height: math.div($miniFilterHeight, 1.8);
  //height: $miniFilterHeight;
  z-index: 3;
  line-height: 1;

  .box {
    border-radius: $radius-large;
    position: absolute;
    height: $miniFilterHeight;
    left: 0.8rem;
    right: 0.8rem;
    bottom: -($miniFilterHeight * 0.5);
    padding: 0;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.3);
  }

  .grid-list {
    height: 100%;
  }

  ._item {
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: $miniFilterPadding;
  }
}

.mini-filter__item {
  color: $black;
  font-weight: $weight-black;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;

  .label {
    font-size: $size-9;
    letter-spacing: 1px;
  }
}

.mini-filter__counter {
}

.mini-filter__divider {
  position: absolute;
  right: 50%;
  width: 1px;
  background-color: $grey;
  top: $miniFilterPadding;
  bottom: $miniFilterPadding;
}

.mini-filter__icon {
  color: $red-dark;
  font-size: $size-4-4;
  margin-right: 0.3rem;
}

.filter-burger {
  color: $white;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 31;

  &:hover {
    color: $white;
  }

  &.hide {
    display: none;
  }
}

.mini-filter--hide {
  opacity: 0;
  display: none;
}

.mini-filter-pin {
  height: $miniFilterHeight;
  margin-top: 10px;

  .mini-filter-wrap {
    transition: top 200ms ease-in-out;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
  }

  &:not(.mini-filter-nav-pin) {
    .mini-filter-wrap {
      top: 10px;
    }

    .mini-filter {
      background-color: $white;
      height: 0;

      .box {
        bottom: -3.625rem;
      }
    }
  }

  &.mini-filter-nav-pin {
    .mini-filter-wrap {
      top: $miniFilterHeight + 0.5rem;
    }
  }
}

.mini-filter-wrap {
  height: $miniFilterHeight + 1;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
}

.mini-filter-content {
  position: relative;
  z-index: 4;
}

.mini-filter--pin .mini-filter-wrap {
  position: fixed;
  top: $navbar-height;
}

.mini-filter--unpin .mini-filter-wrap {
  position: fixed;
  top: 38px;
  .mini-filter {
    height: 0;
  }
}
